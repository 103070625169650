import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import RadioButton from '../../RadioButton';
import StickyElement from '../../StickyElement';
import Button from '../../Button';
import WhiteHeader from '../../WhiteHeader';
import PromotionInfo from './PromotionInfo';
import PersonalAdsLimitPayment from './PersonalAdsLimitPayment';
import PromotionHeader from './PromotionHeader';
import useToggle from '../../../hooks/useToggle';
import usePostMessage from '../../../hooks/usePostMessage';
import { createPaymentQuery } from '../../../queries';
import Bugsnag from '../../../utils/bugsnag';
import { formatDate } from '../../../utils/helpers/common';
import { openSuccessPromotionAlertModal } from '../../../actions';
import { useBridgeState } from '../../../bridge';
import useTogglePromotionModalView from '../../../hooks/useTogglePromotionModalView';

const Promotion = ({
  onCloseHandler,
  content,
  isPremiumView,
  isPaymentMethodView,
  isPersonalAdsLimitView,
  isRestoreView,
  isStickyButton = true
}) => {
  const { isWebView } = useBridgeState();
  const dispatch = useDispatch();
  const router = useRouter();
  const { id } = router.query;
  const { t, i18n } = useTranslation();
  const [termValue, setTermValue] = useState(content?.serviceTermArray[1]);
  const [paymentService, setPaymentService] = useState({});
  const [showAlert, toggleAlert] = useToggle(false);
  const [isPaymentFinished, setPaymentFinished] = useState(false);
  const agreement = t('authentication.agreement');
  const rules = t('pages.current_item.promotion.proposal');
  const phone = '(012) 505-08-04';
  const [createPayment] = useMutation(createPaymentQuery);
  const { onLimitPaymentHandleClick } = useTogglePromotionModalView();

  const onChangeTermsHandler = e => {
    const selectedValue = content?.serviceTermArray.find(
      item => item.id === e.target.value
    );
    setTermValue(selectedValue);
  };

  const onChangeServiceHandler = e => {
    const selectedValue = content?.paymentList.find(
      item => item.id === e.target.value
    );
    setPaymentService(selectedValue);
  };

  const handleSubmit = () => {
    const openedWin =
      paymentService.id !== 'wallet' && window.open('', '_blank');

    createPayment({
      variables: {
        clientMutationId: id,
        paidServiceOptionId: termValue.id,
        paymentMethodId: paymentService.id,
        targetId: id,
        targetType: 'ITEM',
        platform: isWebView ? 'APP' : 'MOBILE'
      }
    })
      .then(data => {
        if (data.data.createPayment.status === 'SUCCESS') {
          if (isRestoreView) {
            return dispatch(openSuccessPromotionAlertModal('restore'));
          }
          if (isPaymentMethodView) {
            return dispatch(openSuccessPromotionAlertModal('limit_payment'));
          }
          dispatch(openSuccessPromotionAlertModal(content?.name));
          onCloseHandler();
        }
        if (data.data.createPayment.status === 'REDIRECT') {
          if (isWebView) {
            const { href } = window.location;
            window.sessionStorage.setItem(
              'paymentReturnTo',
              href.split('#')[0]
            );
            window.location = data.data.createPayment.redirectUrl;
          } else {
            openedWin.location = data.data.createPayment.redirectUrl;
          }
        }
      })
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  usePostMessage({
    postMessageName: 'paymentFinished',
    handler: () => {
      setPaymentFinished(true);
    }
  });

  useEffect(() => {
    if (isPremiumView) {
      setTermValue(content?.serviceTermArray[2]);
    } else {
      setTermValue(content?.serviceTermArray[1]);
    }
    setPaymentService(content?.paymentList[0]);
  }, [isPremiumView, content]);

  useEffect(() => {
    if (isPaymentFinished) {
      if (['bump', 'vip', 'feature'].includes(content?.name)) {
        dispatch(openSuccessPromotionAlertModal(content?.name));
      }
      if (isRestoreView) {
        dispatch(openSuccessPromotionAlertModal('restore'));
      }
      if (isPaymentMethodView) {
        dispatch(openSuccessPromotionAlertModal('limit_payment'));
      }
      onCloseHandler();
    }
  }, [isPaymentFinished]);

  if (isPersonalAdsLimitView) {
    return (
      <PersonalAdsLimitPayment
        message={content?.message}
        additionalMessage={content?.additionalMessage}
        handleSubmit={onLimitPaymentHandleClick}
        {...{ onCloseHandler, id, showAlert, toggleAlert }}
      />
    );
  }
  const button = (
    <Button
      text={t('pages.current_item.buttons.pay')}
      additionalClass="bottom-phone"
      onClickHandler={handleSubmit}
      cypress="pay-button"
    />
  );

  return (
    <div className="promotion">
      {isPaymentMethodView ? (
        <WhiteHeader />
      ) : (
        <PromotionHeader
          icon={content?.icon}
          onCloseHandler={onCloseHandler}
          title={content?.title}
        />
      )}
      {isPaymentMethodView ? (
        <div className="promotion__payment-method">
          <div className="promotion__payment-method-note">
            <Trans
              i18nKey="pages.current_item.promotion.note"
              values={{
                id
              }}
            >
              <Link href={`/items/${id}`}>{id}</Link>
            </Trans>
          </div>
          <PromotionInfo
            message={content?.message}
            additionalClasses={'promotion__info--payment'}
          />
          <PromotionInfo
            message={content?.additionalMessage}
            additionalClasses={'promotion__info--payment'}
          />
        </div>
      ) : (
        <PromotionInfo
          message={content?.message}
          additionalClasses={'promotion__info--bottom-border'}
        />
      )}
      {content?.isPayed && (
        <PromotionInfo
          message={t('pages.current_item.paid_until', {
            date: formatDate(content?.activeUntil, i18n)
          })}
          additionalClasses={'promotion__info--payed'}
        />
      )}
      <div
        className={cn('promotion__terms', {
          'promotion__terms--no-bottom-space': isPaymentMethodView
        })}
      >
        <span className="promotion__terms-title">
          {isRestoreView || isPaymentMethodView
            ? t('pages.current_item.promotion.ads_amount')
            : t('pages.current_item.promotion.term_of_service')}
        </span>
        {content?.serviceTermArray.map((item, i) => {
          return (
            <div key={i} className="promotion__terms-radio-btns">
              <RadioButton
                isCurrentItemPage
                key={`term_${i}`}
                data={item}
                onChangeHandler={onChangeTermsHandler}
                currentValue={termValue?.id || ''}
                additionalClasses="promotion__terms--radio-reverse"
                name="terms"
                cypress="terms"
              />
            </div>
          );
        })}
      </div>
      <div className="promotion__terms">
        <span className="promotion__terms-title">
          {t('pages.current_item.promotion.payment_method')}
        </span>
        {content?.paymentList.map((item, i) => {
          if (!item?.isShow) {
            return null;
          }
          return (
            <div key={i} className="promotion__terms-radio-btns">
              <RadioButton
                isCurrentItemPage
                key={`serv_${i}`}
                data={item}
                onChangeHandler={onChangeServiceHandler}
                currentValue={paymentService?.id || ''}
                additionalClasses="promotion__terms--radio-reverse"
                name="service"
                cypress="service"
              />
            </div>
          );
        })}
      </div>
      <div className="promotion__confirmation">
        <Trans
          i18nKey="pages.current_item.promotion.payment_info"
          values={{
            agreement,
            rules
          }}
        >
          <a href="/pages/terms-and-conditions">{{ agreement }}</a>
          <a href="/pages/proposal">{{ rules }}</a>
        </Trans>
      </div>
      {isPaymentMethodView && (
        <div className="promotion__more-ads">
          <Trans
            i18nKey="pages.current_item.promotion.more_ads"
            values={{
              phone
            }}
          >
            <a href={`tel:${phone}`}>{phone}</a>
          </Trans>
        </div>
      )}
      {isStickyButton ? (
        <StickyElement bottomPos={true} bottomOffset={10}>
          {(isSticky, wrapperRef) => {
            return (
              <div className={'bottom-phone-container'} ref={wrapperRef}>
                <div
                  className={cn(
                    'bottom-phone__wrapper',
                    'bottom-phone__wrapper--sticky'
                  )}
                >
                  {button}
                </div>
              </div>
            );
          }}
        </StickyElement>
      ) : (
        <div className={'promotion__horizontal-space'}>{button}</div>
      )}
    </div>
  );
};

Promotion.propTypes = {
  onCloseHandler: PropTypes.func,
  content: PropTypes.object,
  paymentList: PropTypes.array,
  isPremiumView: PropTypes.bool,
  isPayed: PropTypes.bool,
  isRestoreView: PropTypes.bool,
  isPaymentMethodView: PropTypes.bool,
  isPersonalAdsLimitView: PropTypes.bool,
  isStickyButton: PropTypes.bool
};
export default Promotion;
